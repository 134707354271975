if (document.querySelector("#js-home")) {
  var homeTitle = document.querySelector(".home-title");
  var menu = document.querySelector(".menu");
  var menuClose = document.querySelector(".menu-close");

  homeTitle.addEventListener("click", function () {
    menu.classList.add("is-visible");
  });

  menuClose.addEventListener("click", function () {
    menu.classList.remove("is-visible");
  });
}
